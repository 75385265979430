import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';

import { changeThemeAction } from './actions';
import { getTheme } from './get-theme';

export const theme = combineReducers({
  isDark: createReducer(!(localStorage.getItem('theme') === 'light'))
    .handleAction(changeThemeAction, (state) => {
      console.log('change theme', state);
      localStorage.setItem('theme', state === true ? 'light' : 'dark');
      return state ? false : true;
    }),
  theme: createReducer(getTheme(!(localStorage.getItem('theme') === 'light')))
    .handleAction(changeThemeAction, (state) => {
      const { palette: { type } } = state;
      if (type === 'dark') {
        return getTheme(false);
      }
      return getTheme(true);
    })
});
